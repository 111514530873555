/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:9bdc2a87-0eb4-4622-9a35-8a50d5b15bd8",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_RjvLuopIn",
    "aws_user_pools_web_client_id": "4c0v9l8rdulpi9cij35f0eu2cl",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://63a78coebj.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5sj6wn7ea5achil3ytascwsdyi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
